export function redirectPost(url, data) {
  const form = document.createElement('form');
  document.body.appendChild(form);
  form.method = 'get';
  form.action = url;
  for (const name in data) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = data[name];
    form.appendChild(input);
  }
  form.submit();
}
