import 'es6-promise/auto';

import '../styles/element-variables';

import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

import {
  Button, Select, DatePicker, Checkbox, Slider, Option, Input,
} from 'element-ui';

import lang from 'element-ui/lib/locale/lang/cs-CZ';
import locale from 'element-ui/lib/locale';
import App from '../app.vue';
import { buildStore } from '../store';

locale.use(lang);

Vue.use(VueAxios, axios);

Vue.use(Option);
Vue.use(Slider);
Vue.use(Checkbox);
Vue.use(Input);
Vue.use(Button);
Vue.use(Select);
Vue.use(DatePicker);

Vue.use(Vuex);

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('vue-filter');
  const store = buildStore(element.getAttribute('data-form-values'), element.getAttribute('data-form-state'));

  if (element != null) {
    new Vue({
      el: '#vue-filter',
      store,
      components: {
        App,
      },
      render: h => h(App, {
        props: {
          inititalState: element.getAttribute('initital_state'),
        },
      }),
    });
  }
});
