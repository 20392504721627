import merge from 'deepmerge';

import Vue from 'vue';
import Vuex from 'vuex';
import { getField, updateField } from 'vuex-map-fields';

Vue.use(Vuex);

const formValues = {
  namespaced: true,
  state: {},
  actions: {},
  getters: {
    getField,
    getKinds(state) {
      return state.kinds;
    },
    getGroupedShips(state, getters, rootState, rootGetters) {
      return rootGetters['formState/withTransportAndDelegate'] ? state.grouped_ships : state.grouped_msc_ships;
    },

    getDestinations(state, getters, rootState, rootGetters) {
      const forCruises = rootGetters['formState/isCruise'];
      const kind = rootGetters['formState/getKind'];

      let destinationsResult = [];

      if (forCruises) {
        const groupedShips = getters.getGroupedShips;
        const destinationWithAnyShip = groupedShips.filter(destination => destination.ship_ids.length);
        const destinationIds = destinationWithAnyShip.map(destinationGroup => destinationGroup.dest_id);
        destinationsResult = state.destinations.filter(destination => destinationIds.includes(destination.id));
      } else {
        destinationsResult = rootState.formValues.destinations.filter(destination => destination.categories.includes(kind));
      }

      return destinationsResult.filter(destination => destination.sightseeing !== forCruises);
    },
    getShips(state, getters, rootState, rootGetters) {
      const forCruises = rootGetters['formState/isCruise'];

      const destiantion = rootState.formState.destination;
      const groupedShips = getters.getGroupedShips;

      if (destiantion && forCruises) {
        const shipsInDestination = groupedShips.filter(obj => obj.dest_id === destiantion)[0].ship_ids;
        return state.ships.filter(obj => shipsInDestination.includes(obj.id));
      }
      return [];
    },
    getPorts(state, getters, rootState) {
      const destiantion = rootState.formState.destination;
      const { ship } = rootState.formState;

      if (ship) {
        const { ports } = state;
        const portsInDestination = state.grouped_msc_ports.filter(obj => obj.dest_id === destiantion)[0].ports;
        return ports.filter(obj => portsInDestination.includes(obj.code) && obj.ship_ids.includes(ship));
      }
      return [];
    },
  },
  mutations: {
    updateField,
  },
};

const formState = {
  namespaced: true,
  state: {
    destination: null,
    ship: null,
    port: null,
    dateRange: null,
    priceRange: null,
    kind: 1,
  },
  actions: { },
  getters: {
    getField,
    withTransportAndDelegate(state) {
      return state.kind === 1;
    },
    isCruise(state) {
      const cruiseIds = [
        1, // Plavba s dopravou a delegátem
        2, // Plavba s vlastní dopravou
      ];
      return cruiseIds.includes(state.kind);
    },
    getKind: state => state.kind,
    dateFrom(state) {
      return state.dateRange ? state.dateRange[0] : null;
    },
    dateTo(state) {
      return state.dateRange ? state.dateRange[1] : null;
    },
  },
  mutations: {
    updateField,
  },
};

export function buildStore(initialFromValues, initialFromState) {
  formValues.state = JSON.parse(initialFromValues);
  formState.state = merge(formState.state, JSON.parse(initialFromState));

  return new Vuex.Store({
    modules: {
      formValues,
      formState,
    },
  });
}
